@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

@layer base {
  body {
    font-family: 'DM Sans', sans-serif;
    overflow-x: hidden;
  }
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar,
  .cartSideMenu::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .dropdown-items:hover .dropdown-arrow-none {
    /*  */
    display: flex !important;
  }

  /* .manufacturer-card:hover {
    @for $i from 0 through 19 {
      .manufacturer-name-#{$i} {
        color: #fff;
        background-color: #000;
      }
    }
  } */

  .manufacturer-card:hover .manufacturer-name-0,
  .manufacturer-card:hover .manufacturer-name-1,
  .manufacturer-card:hover .manufacturer-name-2,
  .manufacturer-card:hover .manufacturer-name-3,
  .manufacturer-card:hover .manufacturer-name-4,
  .manufacturer-card:hover .manufacturer-name-5,
  .manufacturer-card:hover .manufacturer-name-6,
  .manufacturer-card:hover .manufacturer-name-7,
  .manufacturer-card:hover .manufacturer-name-8,
  .manufacturer-card:hover .manufacturer-name-9,
  .manufacturer-card:hover .manufacturer-name-10,
  .manufacturer-card:hover .manufacturer-name-11,
  .manufacturer-card:hover .manufacturer-name-12,
  .manufacturer-card:hover .manufacturer-name-13,
  .manufacturer-card:hover .manufacturer-name-14,
  .manufacturer-card:hover .manufacturer-name-15,
  .manufacturer-card:hover .manufacturer-name-16,
  .manufacturer-card:hover .manufacturer-name-17,
  .manufacturer-card:hover .manufacturer-name-18,
  .manufacturer-card:hover .manufacturer-name-19 {
    color: #fff;
    background-color: #000;
  }

  .stories-brand:hover .pagination-container.home-pagination {
    visibility: visible;
  }

  button:disabled {
    background-color: #ccc;
    color: #6b7280;
  }

  .product-tooltip.tooltip {
    position: relative;
    display: inline-block;
  }

  .product-tooltip.tooltip .tooltiptext {
    visibility: hidden;
    width: 160px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -80px;
    opacity: 0;
    transition: opacity 0.3s;
  }

  .product-tooltip.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  button:disabled:hover {
    background-color: #d6d3d3;
    cursor: default;
  }

  .overflowStatus::-webkit-scrollbar {
    display: none;
  }

  .zoom-container {
    position: relative;
    overflow: hidden;
  }

  .pagination-container {
    pointer-events: none;
  }

  .pagination-container button {
    pointer-events: all;
  }

  /* styles.css */
  @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  .marquee {
    white-space: nowrap;
    overflow: hidden;
    animation: marquee 10s linear infinite;
    min-width: 500px;
  }

  .marquee:hover {
    animation-play-state: paused;
  }

  @media (max-width: 457px) {
    .card-xs {
      flex-direction: column;
    }
    .card-xs p {
      font-size: 1rem;
    }
  }
}

@media (max-width: 212px) {
  .view-alt {
    display: none;
  }
}

@media (max-width: 307px) {
  .add-to-cart-txt,
  .contact-us {
    display: none;
  }
  .cart-box,
  .phone {
    display: block;
  }
}

@media (max-width: 340px) {
  .express-shipping-txt {
    display: none;
  }
  .express-shipping-img {
    display: block;
  }
}

@media (max-width: 639px) {
  .view-alt {
    display: none;
  }
  .eye-alt {
    display: flex;
  }
}

@media (max-width: 1023px) {
  .add-cart-txt {
    display: none;
  }
}

/* @tailwind base;
@tailwind components;
@tailwind utilities; */

@layer components {
  :root {
    --carousel-height: 400px;
  }

  .carousel-container {
    @apply relative h-[var(--carousel-height)] w-full overflow-hidden;
  }

  .carousel-slide {
    @apply absolute h-full w-full opacity-0 transition-opacity duration-500;
  }

  .carousel-slide.active {
    @apply z-10 opacity-100;
  }

  .slide-image {
    @apply h-full w-full object-cover;
  }

  .caption {
    @apply absolute bottom-0 left-1/2 -translate-x-1/2 -translate-y-1/2 transform rounded-lg bg-black bg-opacity-50 px-10 py-5 text-lg text-white;
  }

  .button {
    @apply mb-2 mr-2 rounded bg-[#3b82f7] px-5 py-2.5 text-sm font-medium text-white hover:bg-[#1b6ef8] focus:outline-none dark:text-[#3b82f7] dark:hover:text-[#3b82f7] dark:focus:ring-[#3b82f7];
  }

  .button-outline {
    @apply mb-2 mr-2 rounded border border-[#3b82f7] bg-white px-5 py-2.5 text-sm font-medium text-[#3b82f7] hover:bg-gray-100 focus:outline-none dark:border-gray-600 dark:bg-gray-800 dark:text-white dark:hover:border-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-700;
  }

  .button-light {
    @apply mb-2 mr-2 rounded bg-blue-300 px-5 py-2.5 text-base font-medium text-[#3b82f7] hover:bg-[#3b82f7] hover:text-white focus:outline-none group-hover:bg-[#3b82f7] group-hover:text-white dark:border-gray-600 dark:bg-gray-800 dark:text-white dark:hover:border-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-700;
  }

  .ghost {
    @apply mb-2 mr-2 rounded bg-white px-5 py-2.5 text-sm font-medium text-[#3b82f7] hover:bg-gray-100 focus:outline-none dark:border-gray-600 dark:bg-gray-800 dark:text-white dark:hover:border-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-700;
  }

  .rounded {
    @apply rounded-full;
  }

  .nav-list {
    @apply mt-4 flex flex-col rounded-lg border p-4 dark:border-gray-700 dark:bg-gray-800 md:mt-0 md:flex-row md:space-x-1 md:border-0 md:text-sm md:font-semibold md:dark:bg-gray-900;
  }

  .form-label {
    @apply mb-2 block text-sm font-medium text-gray-900 dark:text-white;
  }

  .form-select {
    @apply block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-[#3b82f7] focus:ring-[#3b82f7] dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-[#3b82f7] dark:focus:ring-[#3b82f7];
  }

  .base-button-outline {
    @apply inline-flex items-center rounded-lg border bg-white px-5 py-2.5 text-center text-sm font-medium text-[#3b82f7] ring-1 ring-[#3b82f7] hover:bg-blue-100 focus:outline-none focus:ring-4 focus:ring-blue-300 disabled:opacity-60 dark:text-[#3b82f7] dark:hover:text-[#3b82f7] dark:focus:ring-[#3b82f7];
  }

  .base-button {
    @apply inline-flex items-center rounded-lg bg-[#3b82f7] px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-[#1b6ef8] focus:outline-none focus:ring-4 focus:ring-blue-300 disabled:opacity-60 dark:text-[#3b82f7] dark:hover:text-[#3b82f7] dark:focus:ring-[#3b82f7];
  }

  .CartSideMenu .tooltip {
    @apply invisible absolute bottom-20 left-1/2 z-10 -translate-x-1/2 transform rounded-md bg-blue-500 p-2 text-white opacity-0 transition-opacity duration-300;
  }

  .CartSideMenu:hover .tooltip {
    @apply visible opacity-100;
  }

  .tooltip-text {
    @apply m-0 text-xs;
  }
}
